import React from "react";
import _ from "lodash";

import { Layout } from "../components/index";
import { getPages, htmlToReact, safePrefix } from "../utils";
import Helmet from "react-helmet";
import { BlogElement } from "./blog";
import CtaBooksy from "../components/CtaBooksy";
import { css } from "styled-components/macro";

export default class Post extends React.Component {
  render() {
    const [authorFirstName, authorLastName] =
      this.props?.pageContext?.frontmatter?.title?.split(" ");

    let display_posts = _.orderBy(
      getPages(this.props.pageContext.articles, "/artykuly"),
      "frontmatter.date",
      "desc"
    );
    return (
      <Layout {...this.props}>
        <Helmet>
          <title>
            {_.get(this.props, "pageContext.frontmatter.title") &&
              _.get(this.props, "pageContext.frontmatter.title") + " - "}
            {_.get(this.props, "pageContext.frontmatter.job_position") + " - "}
            {_.get(this.props, "pageContext.site.siteMetadata.title")}
          </title>
          <meta property="og:type" content="profile" />
          {authorFirstName && (
            <meta property="profile:first_name" content={authorFirstName} />
          )}
          {authorLastName && (
            <meta property="profile:last_name" content={authorLastName} />
          )}
        </Helmet>
        <div className="outer">
          <div className="inner-medium">
            <article className="post post-full">
              <header className="post-header">
                <h1 className="post-title">
                  {_.get(this.props, "pageContext.frontmatter.title")}
                </h1>
              </header>
              {_.get(
                this.props,
                "pageContext.frontmatter.content_img_path"
              ) && (
                <div className="post-thumbnail">
                  <img
                    src={safePrefix(
                      _.get(
                        this.props,
                        "pageContext.frontmatter.content_img_path"
                      )
                    )}
                    alt={`${_.get(
                      this.props,
                      "pageContext.frontmatter.title"
                    )} - ${_.get(
                      this.props,
                      "pageContext.frontmatter.job_position"
                    )}`}
                  />
                </div>
              )}
              {_.get(this.props, "pageContext.frontmatter.job_position") && (
                <div className="post-subtitle">
                  {htmlToReact(
                    _.get(this.props, "pageContext.frontmatter.job_position")
                  )}
                </div>
              )}
              <div className="post-content">
                {htmlToReact(_.get(this.props, "pageContext.html"))}
              </div>
            </article>
          </div>
        </div>
        <CtaBooksy />
        {display_posts && display_posts.length > 0 && (
          <div
            className="inner"
            css={css`
              margin-top: 4rem;
            `}
          >
            <div className="post-header">
              <h2 className="post-title">Artykuły tego autora</h2>
            </div>
            <div className="post-feed">
              {_.map(display_posts, (post, post_idx) => (
                <BlogElement post={post} key={post_idx} />
              ))}
            </div>
          </div>
        )}
      </Layout>
    );
  }
}
