import React from "react";
import _ from "lodash";
import { BooksyWidget } from "./BooksyWidget";
import { RESERVE_ONLINE_ENABLED } from "../constants/config";

export default class CtaBooksy extends React.Component {
  render() {
    if (!RESERVE_ONLINE_ENABLED) return null;
    return (
      <section
        id={_.get(this.props, "section.section_id")}
        className="block cta-block bg-accent outer"
      >
        <div className="inner-large">
          <div className="grid">
            <div className="cell block-content">
              <h2 className="block-title">{data.title}</h2>
              <p className="block-subtitle">{data.subtitle}</p>
            </div>

            <div className="cell block-buttons">
              <BooksyWidget variant="ghost" text="Umów online" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const data = {
  title: "Umów się na wizytę",
  subtitle: "Umów online odpowiadający Ci termin",
};
