import React from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import styled, { css } from 'styled-components/macro'

/**
 * variant: 'primary' | 'ghost'
 */
export const BooksyWidget = React.memo(({variant = 'primary', text = 'Umów wizytę'}) => {
  const booksyWidgetRef = React.useRef();
  React.useEffect(() => {
    if (!booksyWidgetRef.current) return;
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://booksy.com/widget/code.js?id=131925&country=pl&lang=pl";
    script.async = true;
    booksyWidgetRef.current.appendChild(script);

    // to prevent the following error after unmount and mount again:
    // Uncaught Error: [Booksy][widget][error] cannot locate current script
    return () => {
      if (!window.booksy) return;
      delete window.booksy;
    };
  }, []);

  return (
    <div>
      <BooksyWidgetContainer ref={booksyWidgetRef} variant={variant} text={text}/>
      <VisuallyHidden>
        <a href="https://booksy.com/pl-pl/131925_dobra-przestrzen-psychoterapia-ruch-rozwoj_psychoterapia_3_warszawa?do=invite">
          Umów online
        </a>
      </VisuallyHidden>
    </div>
  );
});

const BooksyWidgetContainer = styled.div`
  min-height: 94px;
  a {
    border-bottom: none;
  }

  .booksy-widget-container-default.booksy-widget-container-pl .booksy-widget-button {
    ${p => p.variant === 'primary' && css`
      background: #fe8c00;
      border-color: #fe8c00;
      color: #fff;
    
    `};
    
    ${p => p.variant === 'ghost' && css`
      background: #fff;
      color: #4c5267;
      border-color: transparent;
  `}
    
    
    height: auto;
    padding-bottom: 0.625em;
    padding-top: 0.625em;
    box-shadow: 0 20px 30px 0 rgb(76 82 103 / 20%);
    transition: box-shadow .2s ease-in-out;
    margin-bottom: 1em;
    display:flex;
    align-content: center;
    justify-content: center;
    &::after {
      content: "${p => p.text}";
    }
`;
